.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
}
.container {
  display: grid;
  grid-template-columns: 40% 60%;
}
.container > div {
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: bold;
}
.container > div:nth-child(1) {
  padding: 60px;
}
.resetPassword {
  border-left: 1px solid var(--primary-color);
  padding: 10px 220px 0 60px;
}
.password {
  border-left: 1px solid var(--primary-color);
}

.container > div:nth-child(1) select {
  height: 50px;
  background-color: var(--primary-color);
  font-weight: bold;
}
.toggle {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  padding: 15px;
  border-radius: 8px;
}
.mainContainer .container .resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
}
.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
}
.resetPassword input::placeholder {
  font-weight: 600;
  color: black;
}
#submit {
  background-color: #333333;
  color: #fff;
  height: 50px;
  border-radius: 25px;
  margin-top: 30px;
}
#submit:hover {
  color: #fff;
}
.monthStyles {
  padding: 0 10px;
}
.payMentMethod {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  align-items: center;
  padding: 0 20px;
}
.innerPayment {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #333333;
}
.innerPayment input {
  height: 40px;
}
.togDiv {
  display: flex;
  border: 1px solid var(--primary-color);
  padding: 15px 10px;
  border-radius: 13px;
}
#active {
  background-color: var(--primary-color);
}
.innerPayment {
  display: flex;
  width: 80%;
  gap: 20px;
}
.innerPayment p {
  white-space: nowrap;
}
.innerPayment input {
  height: 60px;
}
.innerPayment span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media (max-width: 769px) {
  .mainContainer {
    height: auto;
    border-radius: 0;
    width: 100%;
    padding: 0px;
  }
  .firstDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }
  .container {
    grid-template-columns: 1fr;
  }
  .resetPassword {
    padding: 20px;
  }
  .container > div {
    height: auto;
  }
  .container > div:nth-child(1) {
    padding: 30px 20px;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .resetPassword {
    padding: 10px 20px 0 60px;
  }
  .container > div:nth-child(1) {
    padding: 30px 20px;
  }
}