.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.firstDiv {
  display: flex;
  justify-content: space-between;
}
.firstDiv button {
  height: 60px;
  border-radius: 15px;
}
.head {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.secondDiv {
  display: flex;
  justify-content: space-between;
}

.btn {
  gap: 10px;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  color: #333333;
  opacity: 50%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
}
.timeDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.timeDiv > span {
  display: flex;
  gap: 20px;
  align-items: center;
}
.name {
  display: flex;
  justify-content: space-between;
  padding: 0 180px 0 0;
}
.verified {
  background-color: #15eeb0;
  padding: 2px 5px;
}
.interestDiv {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.photoDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding-right: 100px; */
  width: 40%;
}
.photos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.uploadMain {
  border: 1px dashed gray;
  padding: 90px;
  border-radius: 16px;
  cursor: pointer;
}
.uploadSub {
  border: 1px dashed gray;
  padding: 36px;
  border-radius: 16px;
  cursor: pointer;
}
.subDiv1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subDiv2 {
  display: flex;
  gap: 10px;
}
.mainPhoto {
  width: 200px;
  height: 210px;
  position: relative;
}
.mainPhoto > img {
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}

.subPhoto {
  width: 100px;
  height: 100px;
  position: relative;
}
.subPhoto > img {
  border-radius: 16px;

  height: 100%;
  object-fit: cover;
}
.mainCross {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.css-1cjy4zv {
  width: 50%;
}
.headDiv{
  display: none;
}
.photoIndex {
  position: absolute;
  top: 5px;
  right: 5px;
  /* background-color: black; */
  color: white;
  font-size: 14px;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight:700;
}

@media (max-width: 769px) {
  .MainContainer {
    height: auto;
    border-radius: 0;
    width: 100%;
    padding: 0px;
    gap: 40px;
  }
  .firstDiv{
    padding-inline: 20px;
  }
  .secondDiv {
    flex-direction: column;
    padding-inline: 20px;
  }
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .title{
    font-size: 20px;
  }
  .name p{
    font-size: 18px;
  }
  .photoDiv{
    margin-top: 30px;
    margin-bottom: 40px;
    width: 100%;
    align-items: center;
  }
}
