.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.tableDiv table::-webkit-scrollbar {
  /* height: 1px; */
}
.tableDiv table::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 4px; /* Rounded corners for the scroll thumb */
  height: 1px;
}
.firstDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterDiv {
  display: flex;
  gap: 10px;
}
.tableDiv {
  /* overflow: scroll; */
  height: 64vh;
  width: 71vw;
  font-size: 13px;
  text-align: center !important;
}
.tableDiv::-webkit-scrollbar {
  display: none;
}
.tableDiv div:first-child {
  width: 100% !important;
}
.tableDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.tableDiv tr > th {
  color: #8f8f8f;
  text-align: center;
  white-space: nowrap;
  padding: 15px 0px;
}

.tableDiv tr > td {
  text-align: center;
  text-align: left;
  /* padding: 8px; */
  text-align: center;
  white-space: nowrap;
}

.actionDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
}
.actionDiv2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blackBtn {
  color: #fff;
  background-color: #333333;
  padding: 10px;
  border-radius: 22px;
}
.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 5px 30px 5px 30px;
  border-radius: 22px;
  white-space: nowrap;
}
.blackBtn5 {
  color: #333333;
  background-color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 8px;
  white-space: nowrap;
}
.blackBtn21 {
  color: #277eac;
  background-color: #d2eef3;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  white-space: nowrap;
}
.blackBtn3 {
  color: #ff5d4d;
  background-color: #fbebee;
  padding: 5px 30px 5px 30px;

  border-radius: 22px;
  white-space: nowrap;
}

.blackBtn31 {
  color: #ff5d4d;
  border: 1px solid #ff5d4d;
  background-color: #fff;
  padding: 5px 20px 5px 20px;

  border-radius: 8px;
  white-space: nowrap;
}

.blackBtn311 {
  color: #333333;
  border: 1px solid #333333;
  background-color: #fff;
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border-radius: 8px;
  white-space: nowrap;
}
.blackBtn4 {
  color: #333333;
  background-color: var(--primary-color);
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}
.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 13px;
}

.table th {
  padding: 8px;
  text-align: center;
  position: sticky;
  top: -1px;
  background-color: #fff;
  padding-bottom: 5px;
}

.numberSpan {
  padding: 2px 15px;
  background-color: var(--primary-color);
  border-radius: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteModeldiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  text-align: center;
}
.deleteModeldiv p {
  font-weight: 700;
  font-size: 20px;
}
.deleteModeldiv img {
  width: 20%;
}
.modelFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}
.modelFooter button:nth-child(1) {
  background-color: black;
  color: #fff;
  width: 132px;
  border-radius: 20px;
}

.modelFooter button:nth-child(2) {
  border: 1px solid black;
  width: 132px;
  border-radius: 20px;
}
.spin {
  position: absolute;
  left: 35vw;
  top: 29vh;
  /* left: 57vw;
  top: 50vh; */
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-top: 30px;
}

.tabPanel {
  color: black !important;
  font-weight: 600;
}
.tabPanel:active {
  color: black !important;
}
#activeTab {
  background-color: #8cc9e9;
}
@media (max-width: 1050px) {
  .tableDiv {
    width: 90vw;
  }
}
@media (max-width: 769px) {
  .MainContainer {
    height: auto;
    border-radius: 0;
    width: 100%;
    padding: 0px;
  }
  .firstDiv {
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    background: #b5b5b5;
    padding: 14px 9px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }
  .tableDiv {
    height: auto;
    width: 80vw;
    margin-top: 20px;
  }
  .spin {
    position: absolute;
    left: 40vw;
    top: 250px;
    /* left: 45vw;
    top: 70%; */
  }
}
