.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
.firstDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterDiv {
  display: flex;
  gap: 10px;
}
.tableDiv {
  height: 64vh;
  width: 71vw;
  font-size: 13px;
  text-align: center !important;
}
.tableDiv::-webkit-scrollbar,
.tableDiv table::-webkit-scrollbar {
  display: none;
}
.tableDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: start;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.tableDiv tr > th {
  color: #8f8f8f;
  text-align: start;
  white-space: nowrap;
  padding: 15px 40px;
}

.tableDiv tr > td {
  text-align: left;
  padding-left: 42px;
}

.tableDiv td {
  text-align: left;
  padding: 2px;
  text-align: center;
  margin-bottom: 10px; /* Increase the gap between table elements */
}
.actionDiv {
  display: flex;
  gap: 10px;
}
.actionDiv2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blackBtn {
  color: #fff;
  background-color: #333333;
  padding: 10px;
  border-radius: 22px;
}
.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}
.blackBtn3 {
  color: #ff5d4d;
  background-color: #fbebee;
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}
.blackBtn4 {
  color: #333333;
  background-color: var(--primary-color);
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}
.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 13px;
}

.table th {
  padding: 8px;
  text-align: center;

  position: sticky;
  top: -1px;
  background-color: #fff;
  padding-bottom: 5px;
}
.AvtarTh {
  padding: 0 !important;
}

.table td {
  /* padding: 8px; */
  text-align: center;
}
.numberSpan {
  padding: 2px 15px;
  background-color: var(--primary-color);
  border-radius: 15px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabPanel {
  color: black !important;
  font-weight: 600;
}
.tabPanel:active {
  color: black !important;
}
#activeTab {
  background-color: #8cc9e9;
}
.unseen {
  font-weight: bold;
}
.tableTr td {
  margin-bottom: 7px !important;
  margin-top: 7px !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 320px) and (max-width: 450px) {
  .tableWrapper {
    height: 341px !important;
  }
  .tableDiv > table {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    max-height: 295px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1050px) {
  .tableDiv {
    width: 90vw;
  }
}
@media (max-width: 769px) {
  .MainContainer {
    height: auto;
    border-radius: 0;
    width: 100%;
    padding: 0px;
  }
  .firstDiv {
    justify-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    background: #b5b5b5;
    padding: 14px 9px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }
  .tableDiv {
    height: auto;
    width: 77vw;
    margin-top: 20px;
  }
  /* .tableDiv tr > th {
    padding: 15px 40px 15px 40px;
  } */
}
