.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  overflow: scroll;
  margin-top: -80px;
  text-align: center;
}
.container::-webkit-scrollbar {
  display: none;
}
.container1 {
  display: flex;
  flex-direction: column;
  background-color: #dbebf3;
  padding: 10px;
  white-space: nowrap;
  text-align: center;
  width: 45%;
  font-size: 12px;
  height: 326px;
  margin: auto;
  font-weight: 500;
  border-radius: 13px;
  position: relative;
  justify-content: space-between;
}
.container > .container1 {
  margin-top: 20px;
}
.container1 h5 {
  font-size: 25px;
}
.Span {
  display: flex;
  justify-content: space-between;
  width: 80%;
  /* margin: auto; */
}
.innerSpan {
  display: flex;
  gap: 10px;
}
.submit {
  color: #fff !important;
  padding: 10px;
  border-radius: 25px !important;
  margin-top: 30px;
}
.container2 {
  display: flex;
  gap: 20px;
}
.spanDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.topBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8ce9db;
  position: absolute;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 20px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #fff;
}

@media (min-width: 769px) and (max-width: 1223px) {
  .container2 {
    flex-direction: column;
  }
  .container1 {
    width: 80%;
  }
  .container > .container1 {
    margin-top: 600px;
  }
}

@media (max-width: 769px) {
  .container2 {
    flex-direction: column;
  }
  .container1 {
    width: 90%;
  }
  .container > .container1 {
    margin-top: 150px;
  }
}

@media (min-width: 1950px) {
  .Span {
    width: 55%;
  }
}
