.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 10px;
}
.topDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chatDiv {
  min-height: 45vh;
  margin-bottom: 60px;
}
.sendMsgDiv {
  /* height: 70px; */
  background-color: #f5fafd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  padding: 10px;
}
.sendMsgDiv textarea {
  background-color: #f5fafd;
  width: 90%;
  height: 100%;
  outline: none;
  resize: none;
  border-radius: 13px;
}
.sendMsgDiv div {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
}
.sendMsgDiv div img {
  width: 50%;
  height: 50%;
}
.selectDiv {
  display: flex;
  padding-bottom: 5px;
  white-space: nowrap;
}

.subjectDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subjectDiv input {
  outline: none;
  font-size: 20px;
  font-weight: bold;
}
.subjectDiv input::placeholder {
  outline: none;
  font-size: 24px;
  color: #c5c8cd;
  font-weight: 500;
}

.previewDiv {
  position: absolute;
  height: 100px !important;
  width: 100px !important;
  bottom: 135px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: 5px;
}
.prevCross {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid var(--primary-color);
  padding: 5px;
  font-size: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: red;
  cursor: pointer;
}

@media (max-width: 769px) {
  .MainContainer {
    height: 100%;
    border-radius: 0;
    width: 100%;
    padding: 0px;
    gap: 30px;
    overflow: visible;
  }
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .topDiv {
    padding-inline: 20px;
  }
  .topDiv > p {
    margin-top: 112px;
  }
}
