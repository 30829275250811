.formDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: #fff;
  padding: 10% 15% 10% 15%;
  border-radius: 13px;
}

.formDiv > h2 {
  color: var(--primary-btn-color);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}
.formDiv > Button {
  background-color: var(--primary-btn-color);
  color: #ffffff;
  margin-top: 10px;
  height: 50px;
  border-radius: 30px;
  font-size: 20px;
}
.formDiv input {
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #f5fafd;
  height: 70px;
  width: 70px;
}

.formDiv input::placeholder {
  font-size: 18px;
  color: #7a7a7a;
}
.inputText {
  color: #343434;
  font-size: 16px;
}
.forgetLink {
  text-align: right;
  color: var(--primary-btn-color);
  font-size: 18px;
  font-weight: 600;
}

.otpDiv {
  display: flex;
  justify-content: center;
  gap: 55px;
}
.pDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  margin-top: 8px;
}


.pDiv > p:nth-child(2) {
  color: #9b9b9b;
  text-decoration: underline;
}


.pDiv > p:nth-child(4) {
  color: #9b9b9b;
  margin-top: 20px;
}

.pDiv > p:nth-child(5) {
  color: #9b9b9b;
  text-decoration: underline;
}
.resend{
  background-color: transparent;
}
