.mainContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 100%;
  padding-top: 1%;
  height: 100vh;
  background-color: var(--primary-color);
}

.mainContainerUser {
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 100%;
  padding-top: 1%;
  height: 100vh;
  background-color: rgba(43, 42, 42, 0.81);
}
select {
  outline: none;
}
.mainContainerUser > div {
  height: 96vh;
}

.sideContainer {
  height: 100%;
  padding: 20px;
  transition: transform 0.3s ease;
}
.hamburger {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 1000;
}

.sideContainer::-webkit-scrollbar {
  display: none;
}
.sideIcons {
  font-size: 20px;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--primary-btn-color);
  height: 63vh;
  overflow-x: scroll;
}

.listContainerUser {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #fff;
  height: 63vh;
  overflow-x: scroll;
}

.listContainer::-webkit-scrollbar,
.listContainerUser::-webkit-scrollbar {
  display: none;
}

.listContainer > a > div,
.listContainerUser > a > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  gap: 20px;
  cursor: pointer;
  width: 100%;
  padding-left: 10%;
  font-weight: 700;
}

.listContainer > a > select,
.listContainerUser > a > select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  gap: 20px;
  cursor: pointer;
  width: 100%;
  padding-left: 10%;
  background-color: transparent;
  font-weight: 700;
}

.logout {
  display: flex;
  align-items: center;
  color: var(--primary-btn-color);
  gap: 15px;
  margin-top: 8%;
  font-weight: 700 !important;
  white-space: nowrap;
  font-size: 17px;
  background-color: #c8c8c8;
  padding: 10px 20px;
  border-radius: 13px;
  cursor: pointer;
}
.logoutUser {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 15px;
  margin-top: 8%;
  white-space: nowrap;
  font-size: 17px;
  background-color: #323030;
  padding: 10px 20px;
  border-radius: 13px;
}

.icons {
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 25px;
  padding: 3%;
}
.iconsUser {
  background-color: #464646;
  border-radius: 50%;
  font-size: 25px;
  padding: 3%;
}

.image {
  width: 30%;
  margin-left: 10%;
  margin-bottom: 10%;
}

#active {
  background-color: black;
  color: #fff;
  border-radius: 13px;
}

.numberSpan {
  padding: 2px 10px;
  background-color: #db3b53;
  border-radius: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#AddDiv {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: #fff;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  padding: 10% 10%;

  height: 150px !important;
  align-items: flex-start !important;
}
#activeAd {
  color: #fff;
}
#inactiveAd {
  color: gray;
}
.subDiv {
  display: flex;
  color: #fff;
}
.AddDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  cursor: pointer;
  width: 100%;
  padding: 5% 10%;
  font-weight: 700;
}
.AddDiv div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.adInnerDiv {
  display: flex;
  flex-direction: column;
  margin-left: -10px;
}

@media (max-width: 1050px) {
  .mainContainer {
    background-color: transparent;
    grid-template-columns: 1fr;
  }
  .hamburger {
    position: fixed;
    width: 30px;
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 1000;
  }
  .sideContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: var(--primary-color);
    transform: translateX(-100%);
    z-index: 1000;
    padding-top: 60px;
  }
  .showSidebar > img {
    /* position: relative;
    top: 60px; */
  }
  .showSidebar {
    transform: translateX(0);
  }

  .hamburger .bar,
  .hamburger:before,
  .hamburger:after {
    content: "";
    background-color: #000;
    width: 100%;
    height: 3px;
    display: block;
    transition: 0.4s;
    margin: 5px 0;
  }
  .active:before {
    transform: translate(0px, 10px) rotate(-45deg);
  }
  .active:after {
    transform: translate(0px, -6px) rotate(45deg);
  }
  .hamburger.active .bar {
    opacity: 0;
  }
  
  /* .contentContainer{
    width: 83%;
  } */
}
