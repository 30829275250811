@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@400;700&display=swap");
.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
}
.container {
  display: flex;
  justify-content: space-between;
}
.container > div {
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: bold;
}
.container > div:nth-child(1) {
  width: 40%;
  padding-top: 100px;
}
.container > div:nth-child(2) {
  width: 60%;
}
.name {
  font-size: 22px;
  line-height: 33px;
  font-family: Helvetica;
}
.password {
  display: flex;
  flex-direction: column;
}

.container > div:nth-child(1) select {
  height: 50px;
  background-color: var(--primary-color);
  font-weight: bold;
}
.editBtn {
  display: flex;
  justify-content: flex-end;
}
.profileImg {
  height: 100%;
  width: 60%;
  object-fit: cover;
  border-radius: 20px;
}
.profileImgDiv {
  /* height: 270px; */
  height: 50%;
}
.phoneDivMain {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 40px;
  padding-left: 50px;
  border-left: 1px solid var(--primary-color);
}
.phoneDiv {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.WithMap {
  position: relative;
  display: flex;
}
/* .WithMap div > img:first-child {
  height: 60vh;
} */
.WithMap2 {
  display: flex;
}
.WithMap2 > img {
  height: 60vh;
}
.categoryIcon {
  height: 45px;
  width: 40px;
  position: absolute;
  top: 28vh;
  left: 38%;
}
.WithMap::before,
.WithMap2::before {
  content: "";
  display: flex;
  height: 25px;
  width: 3px;
  background: black;
  border-radius: 28px;
  position: relative;
  top: 100px;
}

.WithMap::after,
.WithMap2::after {
  content: "";
  display: flex;
  height: 55px;
  width: 3px;
  background: black;
  border-radius: 28px;
  position: relative;
  top: 130px;
}
.helvetica-font {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media (min-width: 1250px) and (max-width: 1350px) {
  .fetchImg {
    top: 70% !important;
  }
}
@media (min-width: 769px) and (max-width: 1250px) {
  .fetchImg {
    top: 67% !important;
  }
}
@media (max-width: 769px) {
  .mainContainer {
    height: auto;
    border-radius: none;
    width: auto;
    padding: 0px;
  }
  .container > div {
    height: auto;
  }
  .container {
    flex-direction: column;
    gap: 60px;
  }
  .container > div:nth-child(1) {
    width: 100%;
    align-items: center;
    padding-top: 0px;
  }
  .container > div:nth-child(2) {
    width: 100%;
  }
  .profileImgDiv {
    height: 70%;
    width: 70%;
  }
  .profileImg {
    min-height: 260px;
    height: 100%;
    width: 100%;
  }
  .name {
    font-size: 25px;
  }
  .phoneDivMain {
    padding: 0px 20px;
  }
  .phoneDiv {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 2000px) and (max-width: 4000px) {
  .withMapPhone {
    height: 600px !important;
    width: 300px !important;
  }
  .mapIcon {
    height: 62% !important;
  }
}

@media (min-width: 4000px) and (max-width: 6000px) {
  .withMapPhone {
    height: 950px !important;
    width: 450px !important;
  }
  .invitePhone {
    margin-top: 26vh;
  }
  .mapIcon {
    height: 75% !important;
  }
}
