@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --max-width: 1100px;
  --primary-color: #d9d9d9;
  --primary-btn-color: #333333;
  --poppins-font: "Poppins", sans-serif;
  --quickSand--font: "Quicksand", sans-serif;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
}

.google-visualization-chart {
  padding: 0; /* Remove padding for the chart container */
}
.ant-picker-body {
  background-color: #f3f3f3;
  border-radius: 0 0 18px 18px !important;
}
.ant-picker-calendar {
  background-color: #f3f3f3;
  border-radius: 18px !important;
}

.google-visualization-legend {
  font-size: 14px; /* Change font size */
  font-weight: bold; /* Add bold style */
  color: #333; /* Change font color */
  /* Add any other styles you want to apply to the legend */
}

/* Style the legend items */
.google-visualization-legend-item {
  cursor: pointer; /* Add cursor pointer on hover */
  /* Add any other styles you want to apply to the legend items */
}
.ant-select-selector {
  background-color: transparent;
  border: none;
}
