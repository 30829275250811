.MainContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 96vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll; /* Prevent content overflow */
}
.MainContainer::-webkit-scrollbar,
.chartHead::-webkit-scrollbar {
  display: none;
}

.performanceChartContainer {
  position: relative;
}
.noDataOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: 340px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
h1 {
  color: #06152b;
  font-weight: 700;
  font-size: 36px;
}

h2 {
  color: #06152b;
  font-weight: 700;
  font-size: 20px;
}

.firstContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstContainer > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.firstContainer > div > span {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 2px solid var(--primary-color);
}

.firstContainer > div > span:nth-child(3) > img {
  width: 100%;
  border-radius: 18px;
}
.firstContainer > div > span:nth-child(3) > {
  border: none !important;
}

.firstContainer > div > select:nth-child(1) {
  height: 50px;
  background-color: #f1f4f9;
  width: 60%;
  -webkit-padding-start: 25px;
  border-radius: 18px;
  font-weight: 700;
  border: 2px solid var(--primary-color);
  padding-right: 40px; /* Adjust the padding value as per your requirement */
  background-image: url("../../assets/selectArrow.svg"); /* Replace 'path/to/arrow-image.png' with the path to your arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  -webkit-appearance: none; /* Remove default arrow appearance on WebKit browsers */
  -moz-appearance: none; /* Remove default arrow appearance on Mozilla Firefox */
  appearance: none;
  font-size: 13px;
}
.firstContainer > div > select:nth-child(1) > option {
  width: 9vw;
  -webkit-padding-start: 25px;

  font-size: 13px;
}
.logoutArrow {
  display: flex;
  justify-content: left;
  align-items: bottom;
  padding-top: 35px;
  margin-left: -5px;
}

.cards {
  display: flex;
  height: 160px;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 20px;
  justify-content: space-between;
}
.cardsInner {
  display: flex;
  width: 70%;
  gap: 10px;
  justify-content: space-between;
}

.card {
  border: 1px solid var(--primary-color);
  border-radius: 13px;
  padding: 20px;
  display: flex;
  height: 150px;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}

.title {
  margin-left: 30px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card > h1 {
  font-size: 28px;
  margin-top: -12px;
}

.card > p {
  color: #809fb8;
  font-weight: 700;
}

.card > img {
  height: 38px;
  width: 38px;
}

.chartDiv {
  display: flex;
  gap: 20px;
  height: 45vh;
  overflow: hidden; /* Prevent content overflow */
}

.chart {
  border: 1px solid var(--primary-color);
  border-radius: 18px;
  padding: 20px;
  flex: 5;
  height: 100%;
  overflow: hidden; /* Prevent content overflow */
}

/* .textAlign: 'left', fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' */

.chartHead {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  margin-bottom: 10px;
  overflow: auto;
}
#profileImg {
  border: none;
}

.innerChartHead {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.innerChartHead > select {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  padding: 5px 30px 5px 10px;
  background-image: url("../../assets/selectArrow.svg"); /* Replace 'path/to/arrow-image.png' with the path to your arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  -webkit-appearance: none; /* Remove default arrow appearance on WebKit browsers */
  -moz-appearance: none; /* Remove default arrow appearance on Mozilla Firefox */
  appearance: none;
}

.previous {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profileDetails {
  display: flex;
  gap: 10px;
  padding-right: 40px;
}
.profilePic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}
.logout {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: red;
}
.sideTable {
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: scroll;
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
}
.sideTable::-webkit-scrollbar {
  display: none;
}

.sideTable > h2 {
  color: #06152b;
  font-weight: 700;
  font-size: 17px;
}
.QrDiv {
  border: 2px dashed var(--primary-color);
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
}
.printDiv {
  display: flex;
  justify-content: space-between;
}
.printIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  background-color: #cfebfb;
  padding: 2px 20px;
  border-radius: 20px;
}
.shareIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 20px;
}
.pieDiv {
  width: 30%;
}

@media (min-width: 769px) and (max-width: 1230px) {
  .printDiv {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
}

@media (max-width: 400px) {
  .firstContainer > div > select:nth-child(1) {
    width: 48% !important;
  }
}
@media (min-width: 400px) and (max-width: 769px) {
  .firstContainer > div {
    width: 300px;
  }
  .firstContainer > div > select:nth-child(1) {
    width: 50% !important;
  }
}
@media (max-width: 769px) {
  .MainContainer {
    height: auto;
    width: 100%;
    padding: 0;
    border-radius: 0;
    gap: 0px;
  }
  .firstContainer {
    align-items: center;
    justify-content: flex-end;
    background: #b5b5b5;
    padding: 7px 9px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }
  .sideTable {
    flex-direction: column;
  }
  .cardsInner {
    width: 100%;
    flex-direction: column;
  }
  .cards {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding-inline: 20px;
  }
  .card {
    width: 100%;
  }
  .chartDiv {
    flex-direction: column;
    height: auto;
    padding: 0 20px 50px;
  }
  .chart {
    min-height: 300px;
    padding: 10px;
  }
  .firstContainer > div > select:nth-child(1) {
    width: 70%;
    padding: 10px 37px 10px 0px;
  }
  .firstContainer div {
    justify-content: space-between;
  }
  .fixedNavbar {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .pieDiv {
    margin-top: 40px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .title {
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
