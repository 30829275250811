.filterDiv2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0px;
}

.innerFilter {
  display: flex;
}

.filterMenu {
  width: 45%;
}
.filterItems {
  width: 55%;
  box-sizing: border-box;
  padding-left: 3%;
}

.filterItems > p {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.adTypeRadio {
  display: flex;
  gap: 10px;
  margin-top: 1%;
}

.adTypeRadio > div {
  width: 48%;
  padding: 2.5% 0;
  border: 1px solid #333333;
  border-radius: 10px;
  display: flex;
  gap: 10px;
}
.adTypeRadio > div > input[type="radio"] {
  margin-left: 6%;
}

.dateSelect {
  display: flex;
  gap: 20px;
}

.singlePicker {
  width: 49%;
}
.innerFilter input {
  border: 1px solid var(--primary-color);
  padding: 10px 15px;
  border-radius: 8px;
  outline: none;
}

.blackBtn {
  color: black;
  background-color: #fff;
  padding: 10px 30px;
  border: 1px solid #333333;
  border-radius: 22px;
}
.blackBtn2 {
  color: #fff;
  background-color: #333333;
  padding: 10px 50px 10px 50px;
  border-radius: 22px;
  white-space: nowrap;
}

.modalBody {
  -webkit-padding-start: 0 !important;
  padding-inline-start: 0 !important;
}

.activeCard {
  border-left-width: 8px !important;
  border-left-color: #8cc9e9;
  background-color: #f3fafd;
}

.inactive {
  background-color: #d9d9d9 !important;
  opacity: 40%;
}

@media (max-width: 1050px) {
  .filterMenu {
    margin-top: 20px;
  }
  .adTypeRadio {
    flex-direction: column;
  }
  .adTypeRadio > div {
    width: 100%;
    padding: 9.5% 0;
  }
  .dateSelect {
    flex-direction: column;
    gap: 15px;
  }
  .singlePicker input {
    width: 200%;
    padding-top: 15px;
  }
  .blackBtn,
  .blackBtn2 {
    width: 55%;
    padding-inline: 0px;
  }
}
