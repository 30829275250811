.MainContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
h1 {
  white-space: nowrap;
}
.container1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 30px;
  width: 32vw;
}
.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 1px solid var(--primary-color);
  padding-right: 150px;
}
.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
  width: 20vw;
  border: none;
}

.resetPassword select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: 30vw;
  border: none;
}

.container2 {
  width: 40vw;
}
.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 40px;
}
.btn button {
  height: 60px;
  border-radius: 16px;
}
.dateDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.preview {
  margin-top: 80px;
}
.previewInner {
  border: 1px solid var(--primary-color);
  min-height: 100px;
  border-radius: 35px;
  margin-top: 20px;
  padding: 15px;
  display: flex;
  gap: 10px;
  word-wrap: break-word;
  position: relative;
  min-width: 100px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.spanIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 20px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.citydiv {
  display: flex;
  padding-left: 10px;
  min-height: 60px;
  padding: 10px;
  gap: 5px;
  border-radius: 16px;
  background-color: #ecf1f4;
  width: 20vw;
}
.custom-time {
  width: 300px !important;
}
.citydiv input {
  height: 40px;
  border-radius: 16px;
  outline: none;
  width: 5vw;
}
.selectedCity {
  display: flex;
  margin-top: 5px;
}

.scrollstack {
  max-height: 150px;
  overflow-y: auto;
}
.headDiv {
  display: none;
}
.timeDiv{
  display: flex;
  gap: 15px;
}
.timeDiv input{
 height: auto !important;
 width: auto !important;
 cursor: pointer;
 
}
.resetPassword > p, .dateDiv > p {
  color: gray;
}

@media (min-width: 769px) and (max-width: 1050px) {
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .container1 {
    margin-top: 100px;
  }
  .MainContainer {
    padding: 0px;
    grid-template-columns: 2fr 2fr;
  }
  .container2 {
    width: 50vw;
  }
  .resetPassword {
    padding-right: 20px;
  }
  .btn {
    margin-top: 50px;
  }
}

@media (max-width: 769px) {
  .MainContainer {
    grid-template-columns: 1fr;
    height: 100%;
    border-radius: 0;
    width: 100%;
    padding: 0px;
    gap: 30px;
    overflow: visible;
  }
  .container2 {
    width: 100vw;
  }
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .container1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-inline: 20px;
    width: 100vw;
    margin-top: 112px;
  }
  .resetPassword {
    padding: 0px;
    border-right: 0px;
  }
  .resetPassword input {
    width: 90vw;
  }
  .resetPassword select {
    width: 100vw;
  }
  .preview {
    margin-top: 40px;
    margin-inline: 20px;
  }
  .preview > p {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
  }
  .btn {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
  .btn button {
    width: 80%;
    border: 1px solid black;
  }
}
