.MainContainer {
  display: flex;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
h1 {
  white-space: nowrap;
}
.container1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  padding-left: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 0 0px;
}

.rightSide {
  display: flex;
  margin-top: 10%;
  align-items: center;
  justify-content: center;
}

/* .suggestionImage {
   height: 85vh; 
   width: 21vw;
   margin-left: 15%;
   margin-top: 5%;
} */
.container2 {
  position: relative;
}

.imageContainer {
  position: relative;
  overflow: auto;
}

.imageContainer1 {
  position: relative;
}

.suggestionImage {
  width: 20vw;
  height: 70%;
  margin: auto;
}

.suggestionImage1 {
  width: 21vw;
  height: 100%;
  margin: 0% auto 0px;
}

.imageInside {
  position: absolute;
  top: 60%;
  left: 48%;
  right: 45%;
  transform: translate(-50%, -50%);
  width: 46.5%;
  height: auto;
  object-fit: cover;
}
.imageInside1 {
  position: absolute;
  top: 50%;
  left: 48%;
  right: 45%;
  transform: translate(-50%, -50%);
  width: 46.5%;
  height: auto;
  object-fit: cover;
}

.previewText {
  width: 100%;
}

.previewText p:nth-child(1) {
  font-size: 20px;
  font-weight: bolder;
}

.previewText p:nth-child(2) {
  font-size: 20px;
  line-height: 22px;
  font-weight: bolder;
}

.previewText p:nth-child(3) {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #c2c2c2;
  margin-top: 1.5%;
}

.previewInputImage {
  display: flex;
  justify-content: center;
}

.previewInputImage1 {
  width: 300px;
  display: flex;
  justify-content: center;
  margin-left: -40px;
}

.previewInputImage1 img {
  display: flex;
  /* width: 96%; */
  object-fit: cover;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.paragraph {
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-left: 4.5%; */
  margin-top: 3%;
}

.paragraph1 {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #b9b9b9;
  margin-top: 3%;
  /* margin-left: 4.5%; */
}

.buttons1 {
  border: 1px solid #000;
  padding: 8px 16px;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  width: 46%;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
}
.modalStyle {
  background-color: red !important;
}
.buttons2 {
  border: 1px solid #000;
  padding: 8px 8px;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  width: 46%;
  background-color: #333333;
  color: #ffffff;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
}

.div2 {
  padding: 10px 20px;
}

.div3 {
  display: flex;
  margin-bottom: 10px;
}

.div1 {
  display: flex;
  flex-direction: column;
  width: 300px;
  object-fit: fill;
  background-color: #ffffff;
  /* height: 30vh; */
  margin-top: 2%;
  border-radius: 22px;
  overflow: auto;
}
.previewInputImage img {
  display: flex;
  /* margin-left: 2%; */
  height: 150px;
  width: 250px;
  margin-top: 2%;
  object-fit: fill;
}

.previewAdDetails {
  width: 100%;
  display: flex;
  margin-top: 3%;
  align-items: center;
}

.adPreviewTitle {
  width: 80%;
}

.adPreviewTitle p {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.locations {
  display: flex;
  gap: 7px;
  align-items: center;
}

.locations img {
  height: 2vh;
}
.locations p {
  font-size: 0.8rem;
}
.stars {
  margin-top: 2%;
  margin-left: 2%;
}
.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}
.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
  width: 20vw;
}
.resetPassword textarea {
  background-color: #ecf1f4;
  border-radius: 16px;
  outline: none;
  padding: 10px;
  resize: none;
  width: 70%;
}
.resetPassword select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.resetPassword > p {
  color: gray;
}
.container2 {
  width: 50%;
}
.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 40px;
}
.btn button {
  height: 60px;
  border-radius: 16px;
}
.radioP {
  color: #333333;
  font-weight: 400;
  font-size: 15px;
}
.longString {
  font-size: 12px;
}

@media (min-width: 769px) and (max-width: 1370px) {
  .div3 {
    flex-direction: column;
    gap: 10px;
  }
  .buttons1,
  .buttons2 {
    width: 80%;
  }
}
@media (min-width: 1280px) and (max-width: 1500px) {
  .previewInputImage img {
    height: 100px !important;
    width: 200px !important;
  }
  .para1 {
    font-size: 18px !important;
  }
  .adPreviewTitle {
    right: 8px !important;
  }
  .star {
    right: 25px !important;
  }

  .adPreviewTitle p {
  }
}
@media (min-width: 769px) and (max-width: 1280px) {
  .previewInputImage img {
    height: 80px !important;
    width: 150px !important;
  }
  .para1 {
    font-size: 14px !important;
  }
  .adPreviewTitle {
    right: 8px !important;
  }
  .star {
    right: 23px !important;
  }
}

@media (min-width: 769px) and (max-width: 1050px) {
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .MainContainer {
    padding: 0px;
    grid-template-columns: 2fr 2fr;
  }
  .container2 {
    width: 50vw;
  }
  .container1 {
    margin-top: 100px;
  }
  .resetPassword {
    padding-right: 20px;
  }
  .btn {
    margin-top: 50px;
  }
  .suggestionImage1 {
    width: 26vw;
  }
  .suggestionImage {
    width: 33vw;
  }
  .imageInside {
    width: 45%;
  }
  .imageInside1 {
    width: 40%;
    /* left: 41%; */
  }
  .buttons1,
  .buttons2 {
    padding: 0px;
  }
}

@media (max-width: 769px) {
  .MainContainer {
    flex-direction: column;
    height: 100%;
    border-radius: 0;
    width: 100%;
    padding: 0px;
    gap: 30px;
    overflow: visible;
  }
  .imageContainer {
    overflow: hidden;
  }
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .div1 {
    height: 40vh;
  }
  .container1 {
    width: 90vw;
    box-shadow: none;
    margin-top: 100px;
  }
  .container2 {
    width: 100%;
  }
  .imageContainer1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .suggestionImage1 {
    width: 100vw;
    margin-left: 10px;
  }
  .suggestionImage {
    width: 100vw;
    margin-left: 10px;
  }
  .imageInside {
    width: 70%;
    left: 50%;
  }
  .imageInside1 {
    width: 70%;
    left: 50%;
  }
  .buttons1,
  .buttons2 {
    padding: 0px;
  }
  .btn {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
  .btn button {
    width: 80%;
  }
  .resetPassword input,
  .resetPassword textarea {
    width: 85vw;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .previewText {
    display: block !important;
    margin-left: 0px;
  }
  .para1 {
    font-size: 24px !important;
  }
  .sugImage {
    height: 200px !important;
    width: 90% !important;
  }
  .mapIcon {
    height: 65% !important;
  }
}

@media (min-width: 4000px) and (max-width: 6000px) {
}
