.MainContainer {
  display: grid;
  grid-template-columns: 2fr 2fr;
  background-color: #fff;
  height: 95vh;
  border-radius: 35px;
  width: 98%;
  padding: 3%;
  gap: 30px;
  overflow: scroll;
}
.MainContainer::-webkit-scrollbar {
  display: none;
}
h1 {
  white-space: nowrap;
}
.container1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 30px;
}
/* .container1 span {
  color: gray;
  font-size: 20px;
} */
.container1 select {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.resetPassword > p{
  color: gray;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.resetPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.resetPassword input {
  background-color: #ecf1f4;
  height: 60px;
  border-radius: 16px;
  outline: none;
}

.resetPassword textarea {
  background-color: #ecf1f4;
  border-radius: 16px;
  display: flex;
  resize: none;
  align-items: center;
}
.btn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 40px;
}
.btn button {
  height: 60px;
  border-radius: 16px;
}
.dateDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.attachSpan {
  display: flex;
  background-color: #ecf1f4;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}
.attachSpan > p {
  font-size: 14px;
}
.headDiv{
  display: none;
}


@media (max-width: 769px) {
  .MainContainer {
    grid-template-columns: 1fr;
    height: 100%;
    border-radius: 0;
    width: 100%;
    padding: 0px;
    gap: 30px;
    overflow: visible;
  }
  .headDiv {
    background: #b5b5b5;
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    display: block;
  }
  .container1 {
    padding: 0px 20px;
  }
  .btn {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
  .btn button {
    width: 80%;
  }
}
